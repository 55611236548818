import './style.css';
import {Map, View} from 'ol';
import GeoJSON from 'ol/format/GeoJSON.js';
import {Cluster, OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {Circle, Fill, Stroke, Style} from 'ol/style.js';
import Overlay from 'ol/Overlay.js';

const blue = new Style({
    image: new Circle({
        radius: 8,
        fill: new Fill({
            color: [0, 153, 255, 1],
        }),
        stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 3,
        }),
    })
});

let redStyles = [];
function circleStyle(feature) {
    let featuresLen = feature.get('features').length;
    if (featuresLen == 1 &&
        feature.get('features')[0].get('Type') === 'single') {
        return blue;
    }
    if (!redStyles[featuresLen]) {
        redStyles[featuresLen] = new Style({
            image: new Circle({
            radius: 7 + Math.sqrt(featuresLen),
            fill: new Fill({
                color: [255, 153, 0, 1],
            }),
            stroke: new Stroke({
                color: [255, 255, 255, 1],
                width: 3,
            }),
        })
    })
    }
    return redStyles[featuresLen];
}

const vectorSource = new VectorSource({
    url: './data/jugglingeverywhere.json',
    format: new GeoJSON()
});

const clusterSource = new Cluster({
    distance: 40,
    minDistance: 35,
    source: vectorSource,
});

const vectorLayer = new VectorLayer({
    source: clusterSource, 
    style: circleStyle,
});

const map = new Map({
    target: 'map',
    layers: [
        new TileLayer({
            source: new OSM()
        }),
        vectorLayer
    ],
    view: new View({
        center: [0, 0],
        zoom: 2,
        enableRotation: false
    })
});

function closestFeature(pixel, target) {
    return target.closest('.ol-control')
    ? undefined
    : map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
    });
}    

const popup = new Overlay({
    element: document.getElementById('popup'),
});
map.addOverlay(popup);

function closePopup() {
    let popover = bootstrap.Popover.getInstance(element);
    if (popover) {
        popover.dispose();
    }
}

function setPopup(evt) {
    const feature = closestFeature(evt.pixel, evt.originalEvent.target);
    if (feature) {
        popup.setPosition(feature.getGeometry().flatCoordinates);
        closePopup();
        let featureDateText = feature.get('features')[0].get('Date');
        let featureDate = new Date(parseInt('20' + featureDateText.substr(6, 2)),
                                   parseInt(featureDateText.substr(3, 2)) - 1,
                                   parseInt(featureDateText.substr(0, 2)));
        let tooltipText = '<p>' + featureDate.toDateString();
        if (!(feature.get('features').length == 1 &&
            feature.get('features')[0].get('Type') === 'single')) {
            tooltipText += ' + more dates';
        }
        tooltipText += '</p><p><a href="' 
                       + feature.get('features')[0].get('Instagram')
                       + '" target="_blank">View on Instagram</a></p>';
        let popover = bootstrap.Popover.getInstance(element);
        popover = new bootstrap.Popover(element, {
            animation: false,
            container: element,
            content: tooltipText,
            html: true,
            placement: 'top',
        });
        popover.show();
    } else {
        closePopup();
    }
}

const element = popup.getElement();
map.on('click', function (evt) {
    setPopup(evt);
});

function checkZoom() {
    let newZoom = map.getView().getZoom();
    if (newZoom != currentZoom) {
        currentZoom = newZoom;
        closePopup();
    }
}

let currentZoom = 0;
map.on('movestart', function (evt) {
    checkZoom();
});
map.on('moveend', function (evt) {
    checkZoom();
});
